<template>
  <v-card>
    <v-card-title>
      Nueva línea liquidación {{ idLiquidacion }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="mt-4">
      <v-textarea :rules="[rules.req]" label="Descripción" filled no-resize auto-grow v-model="desc"></v-textarea>
      <v-text-field :rules="[rules.req, rules.isNumber]" label="Importe" filled v-model="importe"></v-text-field>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click.stop="$emit('input', false)" color="error" text>Cancelar</v-btn>
      <v-btn @click.stop="saveLine" color="primary" text>Aceptar</v-btn>
    </v-card-actions>
  </v-card>  
</template>

<script>
import {req, isNumber} from '@/utils/validations.js'

export default {
  props: {
    idLiquidacion: String | Number
  },
  data() {
    return {
      desc: '',
      importe: null,

      rules: {req, isNumber}
    }
  },
  methods: {
    async saveLine() {
      axios({
        method: 'POST',
        url: `${process.env.VUE_APP_OUR_API_URL}/liquidaciones/nuevaLinea.php`,
        data: {
          token: this.$store.getters.getJwtEmpresa,
          idLiquidacion: this.idLiquidacion,
          desc: this.desc,
          importe: this.importe
        }
      }).then(() => {
        this.$emit('snack', 'Se ha añadido la línea correctamente');
        this.$emit('reload');
        this.$emit('input', false);
      }).catch(() => {
        this.$emit('snack', 'No se ha podido añadido la línea');
        this.$emit('reload');
        this.$emit('input', false);
      })
    }
  }
}
</script>

<style>

</style>